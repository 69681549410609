import React from "react"
import "./content.scss"

class Content extends React.Component {

  constructor() {
    super();
    this.state = {
      mounted: false,
    };
  }

  componentDidMount() {

    setTimeout(() => {
        this.setState({
          mounted: true,
        })
      }, 500)
  }

  render() {

    const { mounted } = this.state;

    return (
      <>
        <div className={`content ${mounted && "content--visible"}`}>
          <p className={"content__artshow-title"}>
            Look At All<br/> These Things.
          </p>


        <p className={"content__subcopy"}>
            The Annual Firstborn <br/>
            Art Show, 2019.
          </p>

        </div>
       
      </>
    )
  }
}
export default Content;
